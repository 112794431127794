<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Single Criteria Matching</span>
    </div>
    <div v-if="loading">
      <v-card>
        <v-card-text>
          <v-container>
            <v-progress-circular class="mx-auto flex" indeterminate color="primary"></v-progress-circular>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <v-card v-else>
      <DataTableHeader show-search-box show-export table-id="organisation-table table" :headers="headers" @update:search="searchText = $event">
        <div style="flex-grow: 1" class="pl-2 ml-4 d-flex items-start">
          <v-text-field
            v-if="searchType === 'location'"
            v-model="selectedItem"
            label="City"
            style="max-width: 200px"
            dense
            outlined
            hide-details="auto"
            :error-messages="showSelectedItemsError ? ['You must type a location'] : []"
          ></v-text-field>
          <v-autocomplete
            v-else-if="searchType === 'superConnectMe'"
            class="mb-3"
            style="max-width: 300px"
            v-model="selectedItem"
            label="Super Connect Me"
            outlined
            dense
            :menu-props="{ offsetY: true }"
            :items="[
              { name: 'Yes', id: true },
              { name: 'No', id: false },
            ]"
            item-text="name"
            item-value="id"
            hide-details="auto"
            :error-messages="showSelectedItemsError ? ['You must select one item'] : []"
          ></v-autocomplete>
          <v-autocomplete
            v-else
            style="max-width: 300px"
            v-model="selectedItems"
            multiple
            label="Select Item"
            outlined
            dense
            :menu-props="{ offsetY: true }"
            :items="getItems"
            item-text="name"
            item-value="id"
            hide-details="auto"
            :error-messages="showSelectedItemsError ? ['You must select at least one item'] : []"
          >
            <template v-slot:selection="{ index }">
              <span v-if="index === 0" class="text-gray-700 py-1 text-14">{{ selectedItems.length }} item(s) Selected</span>
            </template>
          </v-autocomplete>
          <v-btn class="ml-3" @click="onSelectionChange">Apply</v-btn>
        </div>
      </DataTableHeader>
      <v-data-table id="organisation-table" data-has-actions="true" :headers="$filterHeaders(headers)" :items="companyList" sort-by="id" :search="searchText" @click:row="viewItem">
        <template v-slot:item.outcomesText="{ item }">
          <v-chip v-for="outcome in item.outcomesText" :key="outcome" small>
            {{ outcome }}
          </v-chip>
        </template>
        <template v-slot:item.challengesText="{ item }">
          <v-chip v-for="challenge in item.challengesText" :key="challenge" small>
            {{ challenge }}
          </v-chip>
        </template>
        <template v-slot:item.techAreasText="{ item }">
          <v-chip v-for="techArea in item.techAreasText" :key="techArea" small>
            {{ techArea }}
          </v-chip>
        </template>
        <template v-slot:item.industriesText="{ item }">
          <v-chip v-for="industry in item.industriesText" :key="industry" small>
            {{ industry }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-btn icon color="gray" @click.stop="editItem(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="gray" @click.stop="showIndividuals(item)">
              <v-icon small>mdi-account</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.actions2="{ item }">
          <v-btn icon color="gray" class="mx-auto" @click.stop="deleteItem(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';
import CreateCompanyModal from '@/components/company/CreateCompanyModal';
import CompanyIndividualsModal from '@/components/company/CompanyIndividualsModal';
import ViewCompanyModal from '@/components/company/ViewCompanyModal.vue';

export default {
  name: 'SimpleMatch',
  components: { DataTableHeader },
  data: () => ({
    headers: [
      { text: 'Edit', value: 'actions', sortable: false, width: '30px', align: 'center' },
      { text: 'Company Name', value: 'name' },
      { text: 'City', value: 'location', hideable: true, show: true },
      { text: 'Country', value: 'country.name', hideable: true, show: false },
      { text: 'Region', value: 'region', hideable: true, show: false },
      { text: 'Type', value: 'companyType.name', hideable: true, show: true },
      { text: 'Inn. Areas', value: 'techAreasText', hideable: true, show: false },
      { text: 'Description', value: 'description', hideable: true, show: false },
      { text: 'Outcomes', value: 'outcomesText', hideable: true, show: false },
      { text: 'Challenges', value: 'challengesText', hideable: true, show: false },
      { text: 'Industries', value: 'industriesText', hideable: true, show: false },
      { text: 'Tags', value: 'tagsText', hideable: true, show: false },
      { text: 'Website', value: 'website', hideable: true, show: false },
      // { text: 'Geography of Focus', value: 'geographyOfFocus', hideable: true, show: false },
      { text: 'Raising Now', value: 'raisingNow', hideable: true, show: false },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions2', sortable: false, width: '30px', align: 'center' },
    ],
    searchText: '',
    loading: false,
    selectedItem: null,
    selectedItems: [],
    showSelectedItemsError: false,
    searchType: null,
  }),
  computed: {
    ...mapState(['companies', 'challenges', 'outcomes', 'techAreas', 'industries', 'regions', 'companyTypes', 'tags']),
    ...mapGetters(['getStartupStages', 'getCountriesArray']),
    getItems() {
      if (this.$route.query?.challenge) return this.challenges;
      if (this.$route.query?.outcome) return this.outcomes;
      if (this.$route.query?.techArea) return this.techAreas;
      if (this.$route.query?.stage) return this.getStartupStages;
      if (this.$route.query?.industry) return this.industries;
      if (this.$route.query?.country) return this.getCountriesArray.map((item) => ({ name: item.name, id: item.code }));
      if (this.$route.query?.region) return this.regions;
      if (this.$route.query?.companyType) return this.companyTypes;
      if (this.$route.query?.tag) return this.tags;
      return [];
    },
    companyList() {
      return this.companies.map((c) => {
        return {
          ...c,
          outcomesText: c.outcomes.map((o) => o.name),
          challengesText: c.challenges.map((o) => o.name),
          techAreasText: c.techAreas.map((o) => o.name),
          industriesText: c.industries.map((o) => o.name),
          tagsText: c.tags.map((o) => o.name),
          country: c?.country?.name,
          region: c.region,
          location: c.location,
          updatedAt: dayjs(c.updated_at).format(DEFAULT_DATE_FORMAT),
        };
      });
    },
  },
  created() {
    this.bootstrap();
  },
  methods: {
    ...mapActions(['fetchCompanies', 'deleteCompany']),
    ...mapMutations(['SET_SEARCH_FILTER_DATA']),
    bootstrap() {
      this.fetchCompaniesData({
        challenge: this.$route.query?.challenge,
        outcome: this.$route.query?.outcome,
        techArea: this.$route.query?.techArea,
        stage: this.$route.query?.stage,
        industry: this.$route.query?.industry,
        location: this.$route.query?.location,
        country: this.$route.query?.country,
        region: this.$route.query?.region,
        companyType: this.$route.query?.companyType,
        tag: this.$route.query?.tag,
        superConnectMe: this.$route.query?.superConnectMe,
      });
      this.SET_SEARCH_FILTER_DATA({
        challenge: this.$route.query?.challenge,
        outcome: this.$route.query?.outcome,
        techArea: this.$route.query?.techArea,
        stage: this.$route.query?.stage,
        industry: this.$route.query?.industry,
        location: this.$route.query?.location,
        country: this.$route.query?.country,
        region: this.$route.query?.region,
        companyType: this.$route.query?.companyType,
        tag: this.$route.query?.tag,
        superConnectMe: this.$route.query?.superConnectMe,
      });

      if (this.$route.query?.challenge) {
        this.selectedItems = this.$route.query?.challenge?.split(',').map((i) => parseInt(i)) || [];
      }
      if (this.$route.query?.outcome) {
        this.selectedItems = this.$route.query?.outcome?.split(',').map((i) => parseInt(i)) || [];
      }
      if (this.$route.query?.techArea) {
        this.selectedItems = this.$route.query?.techArea?.split(',').map((i) => parseInt(i)) || [];
      }
      if (this.$route.query?.stage) {
        this.selectedItems = this.$route.query?.stage?.split(',').map((i) => parseInt(i)) || [];
      }
      if (this.$route.query?.industry) {
        this.selectedItems = this.$route.query?.industry?.split(',') || [];
      }
      if (this.$route.query?.tag) {
        this.selectedItems = this.$route.query?.tag?.split(',') || [];
      }
      if (this.$route.query?.country) {
        this.selectedItems = this.$route.query?.country?.split(',') || [];
      }
      if (this.$route.query?.location) {
        this.searchType = 'location';
        this.selectedItem = this.$route.query?.location || '';
      }
      if ('superConnectMe' in this.$route.query) {
        this.searchType = 'superConnectMe';
        const { superConnectMe } = this.$route.query;
        this.selectedItem = superConnectMe === 'true';
      }
      if (this.$route.query?.region) {
        this.selectedItems = this.$route.query?.region?.split(',') || [];
      }
      if (this.$route.query?.companyType) {
        this.selectedItems = this.$route.query?.companyType?.split(',').map((i) => parseInt(i)) || [];
      }

      this.headers.find((h) => h.value === 'challengesText').show = !!this.$route.query?.challenge;
      this.headers.find((h) => h.value === 'outcomesText').show = !!this.$route.query?.outcome;
      this.headers.find((h) => h.value === 'techAreasText').show = !!this.$route.query?.techArea;
      this.headers.find((h) => h.value === 'location').show = !!this.$route.query?.location;
      this.headers.find((h) => h.value === 'country.name').show = !!this.$route.query?.country;
      this.headers.find((h) => h.value === 'region').show = !!this.$route.query?.region;
    },
    onSelectionChange() {
      this.showSelectedItemsError = false;

      if (this.searchType === 'location') {
        if (!this.selectedItem.length) {
          this.showSelectedItemsError = true;
          return;
        }
      } else if (this.searchType === 'superConnectMe') {
        // do nothing
      } else {
        if (!this.selectedItems.length) {
          this.showSelectedItemsError = true;
          return;
        }
      }

      const { query, fullPath, hash, params } = this.$route;
      const newQuery = { ...query };

      if (this.$route.query?.challenge) {
        newQuery.challenge = this.selectedItems.join(',');
      }
      if (this.$route.query?.outcome) {
        newQuery.outcome = this.selectedItems.join(',');
      }
      if (this.$route.query?.techArea) {
        newQuery.techArea = this.selectedItems.join(',');
      }
      if (this.$route.query?.stage) {
        newQuery.stage = this.selectedItems.join(',');
      }
      if (this.$route.query?.industry) {
        newQuery.industry = this.selectedItems.join(',');
      }
      if (this.$route.query?.tag) {
        newQuery.tag = this.selectedItems.join(',');
      }
      if (this.$route.query?.location) {
        newQuery.location = this.selectedItem;
      }
      if (this.$route.query?.country) {
        newQuery.country = this.selectedItems.join(',');
      }
      if (this.$route.query?.region) {
        newQuery.region = this.selectedItems.join(',');
      }
      if (this.$route.query?.companyType) {
        newQuery.companyType = this.selectedItems.join(',');
      }
      if ('superConnectMe' in this.$route.query) {
        newQuery.superConnectMe = this.selectedItem;
      }

      this.$router
        .push({
          query: newQuery,
          fullPath,
          hash,
          params,
        })
        .then(() => {
          this.bootstrap();
        })
        .catch(() => {});
    },
    onSearchSubmit(filterData) {
      this.fetchCompaniesData(filterData);
    },
    viewItem(item) {
      this.$showModal(ViewCompanyModal, { id: item.id });
    },
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteCompany(item.id);
          modal.close();
        },
      });
    },
    showIndividuals(item) {
      this.$showModal(CompanyIndividualsModal, { companyId: item.id });
    },
    editItem(item) {
      this.$showModal(CreateCompanyModal, { id: item.id });
    },
    openCreateModal() {
      this.$showModal(CreateCompanyModal);
    },
    async fetchCompaniesData(filters) {
      this.loading = true;
      await this.fetchCompanies({ filters });
      this.loading = false;
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>
<style lang="scss">
#organisation-table {
  td:not(:first-child):not(:last-child) {
    cursor: pointer;
  }
}
</style>
