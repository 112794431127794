<template>
  <v-dialog v-model="dialog" max-width="900px" @keydown.esc="cancel">
    <v-card>
      <v-card-title>
        <span class="headline">Individuals</span>
      </v-card-title>
      <v-card-text>
        <v-simple-table fixed-header height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">First Name</th>
                <th class="text-left">Last Name</th>
                <th class="text-left">Job Title</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in companyIndividuals" :key="item.individualId">
                <td>{{ item.individual.firstName }}</td>
                <td>{{ item.individual.lastName }}</td>
                <td>{{ item.jobTitle }}</td>
              </tr>
              <tr v-if="!companyIndividuals.length">
                <td colspan="3" class="text-center font-weight-medium">There is no record</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 px-5" @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CompanyIndividualsModal',
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: true,
      companyIndividuals: [],
    };
  },
  async created() {
    this.companyIndividuals = await this.fetchIndividualsByCompanyId(this.companyId);
  },
  methods: {
    ...mapActions(['fetchIndividualsByCompanyId']),
    cancel() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
