<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay max-width="900px" scrollable @keydown.esc="close">
    <v-card>
      <v-toolbar max-height="64" dark color="primary" style="margin-top: -1px; border-radius: 0">
        <v-toolbar-title>View Organisation</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="Organisation Name" :text="name"></ViewItem>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="Tracking Stats" :text="trackingStats"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Type of Organisation" :text="selectedCompanyType"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem v-if="selectedSubCompanyStage" label="Stage of Organisation" :text="selectedSubCompanyStage"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="City" :text="location"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Region" :text="region"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Country" :text="country"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Website" :text="website"></ViewItem>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="Description" :text="description"></ViewItem>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="SC Insights" :text="SCInsights"></ViewItem>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="Tracking Log" :text="bio"></ViewItem>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="Raising Now (in past quarter)">{{ raisingNow ? 'Yes' : 'No' }}</ViewItem>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <ViewItem label="Tag(s)">
                <v-chip v-for="(item, index) in selectedTags" :key="index" class="mr-1" small>{{ item }}</v-chip>
              </ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Challenges">
                <v-chip v-for="(item, index) in selectedChallenges" :key="index" class="mr-1" small>{{ item }}</v-chip>
              </ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Outcome(s) of Interest">
                <v-chip v-for="(item, index) in selectedOutcomes" :key="index" class="mr-1" small>{{ item }}</v-chip>
              </ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Innovation Area(s)">
                <v-chip v-for="(item, index) in selectedTechAreas" :key="index" class="mr-1" small>{{ item }}</v-chip>
              </ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Industries">
                <v-chip v-for="(item, index) in selectedIndustries" :key="index" class="mr-1" small>{{ item }}</v-chip>
              </ViewItem>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <ViewItem :text="teamSize" label="Team Size"></ViewItem>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ViewItem :text="founded" label="Founded"></ViewItem>
            </v-col>

            <template v-if="isFunder">
              <v-col cols="12" sm="12" md="12" class="mt-2 mb-0 pb-0">
                <div class="font-weight-bold">Funder Type:</div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem label="Type of Funding" :text="fundingTypes"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem label="Stages of Interest" :text="fundingStages"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem label="Ticket Size of Interest" :text="fundingTicketSizes"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem label="Minimum Revenue per month" :text="`£${fundingMinimumMonthlyRevenueStr}`"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem label="Last Active" :text="fundingLastActive"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="selectedBusinessModels" label="Business Model(s)"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="selectedFundingRoles" label="Funder Role(s)"></ViewItem>
              </v-col>
            </template>

            <template v-if="isStartup">
              <v-col cols="12" sm="12" md="12" class="mt-2 mb-0 pb-0">
                <div class="font-weight-bold">Requirements</div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="amountCurrentlyRaising" label="Amount Currently Raising"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="`£${currentValuationStr}`" label="Current Valuation"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="`£${minimumRevenuePerMonthStr}`" label="Minimum Revenue per month"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="ticketSizes" label="Ticket Size of Interest"></ViewItem>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <ViewItem :text="fundingRaised" label="Funding Raised"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="selectedBusinessModels" label="Business Model(s)"></ViewItem>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <ViewItem :text="pitchDeckUrl" label="Pitch Deck URL"></ViewItem>
              </v-col>
            </template>

            <v-col cols="12" sm="6" md="6">
              <ViewItem :text="geographyOfFocus" label="Geography of Focus"></ViewItem>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <ViewItem label="Previous Challenges Registered">
                <v-chip v-for="(item, index) in selectedPreviousChallenges" :key="index" class="mr-1" small>{{ item }}</v-chip>
              </ViewItem>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <ViewItem :text="superConnectMe" label="Super Connect Me"></ViewItem>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { rules } from '@/helpers/validation';
import { formatDate } from '@/helpers/datetime';

export default {
  name: 'ViewCompanyModal',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    defaultCompanyTypeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      valid: true,
      createAnother: false,
      name: '',
      trackingStats: '',
      bio: '',
      SCInsights: '',
      location: '',
      region: '',
      country: '',
      website: '',
      raisingNow: false,
      description: '',
      selectedChallenges: [],
      selectedPreviousChallenges: [],
      selectedOutcomes: [],
      selectedCompanyType: '',
      selectedCompanyTypeId: '',
      selectedSubCompanyStage: '',
      selectedTechAreas: [],
      selectedIndustries: [],
      selectedTags: [],
      fundingTypes: '',
      fundingStages: '',
      fundingTicketSizes: '',
      fundingMinimumMonthlyRevenue: '',
      fundingLastActive: '',
      amountCurrentlyRaising: '',
      currentValuation: '',
      minimumRevenuePerMonth: '',
      selectedBusinessModels: '',
      fundingRaised: '',
      pitchDeckUrl: '',
      selectedFundingRoles: '',
      ticketSizes: '',
      geographyOfFocus: '',
      superConnectMe: '',
      teamSize: '',
      founded: '',
      company: null,
      rules,
    };
  },
  computed: {
    ...mapGetters(['getFunderCompanyTypeId', 'getStartupCompanyTypeId']),
    isFunder() {
      return this.getFunderCompanyTypeId === this.selectedCompanyTypeId;
    },
    isStartup() {
      return this.getStartupCompanyTypeId === this.selectedCompanyTypeId;
    },
    minimumRevenuePerMonthStr() {
      return this.minimumRevenuePerMonth?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || '';
    },
    fundingMinimumMonthlyRevenueStr() {
      return this.fundingMinimumMonthlyRevenue?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || '';
    },
    currentValuationStr() {
      return this.currentValuation?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || '';
    },
  },
  created() {
    if (this.id) {
      this.fetchCompanyById(this.id).then((res) => {
        this.$set(this, 'company', res);

        if (this.company) {
          const {
            name,
            trackingStats,
            location,
            description,
            website,
            region,
            raisingNow,
            bio,
            SCInsights,
            country,
            challenges,
            previousChallenges,
            outcomes,
            techAreas,
            tags,
            companyType,
            companyStage,
            fundingTypes,
            fundingTicketSizes,
            fundingStages,
            funding,
            amountCurrentlyRaising,
            currentValuation,
            minimumRevenuePerMonth,
            businessModels,
            fundingRoles,
            ticketSizes,
            geographyOfFocus,
            fundingRaised,
            pitchDeckUrl,
            superConnectMe,
            teamSize,
            founded,
          } = this.company;
          this.name = name;
          this.trackingStats = trackingStats;
          this.location = location;
          this.bio = bio;
          this.SCInsights = SCInsights;
          this.description = description;
          this.website = website;
          this.raisingNow = raisingNow || false;
          this.region = region;
          this.teamSize = teamSize;
          this.founded = founded;
          this.superConnectMe = superConnectMe ? 'Yes' : 'No';
          this.country = country?.name;
          this.selectedChallenges = challenges.map((i) => i.name);
          this.selectedPreviousChallenges = previousChallenges.map((i) => i.name);
          this.selectedOutcomes = outcomes.map((i) => i.name);
          this.selectedTechAreas = techAreas.map((i) => i.name);
          this.selectedIndustries = techAreas.map((i) => i.name);
          this.selectedTags = tags.map((i) => i.name);
          this.selectedCompanyType = companyType?.name;
          this.selectedCompanyTypeId = companyType?.id;
          this.selectedSubCompanyStage = companyStage?.name;
          this.fundingTypes = fundingTypes.map((i) => i.name).join(', ');
          this.fundingStages = fundingStages.map((i) => i.name).join(', ');
          this.fundingTicketSizes = fundingTicketSizes.map((i) => i.name).join(', ');
          this.fundingLastActive = funding?.lastActive ? formatDate(funding.lastActive.substr(0, 10)) : '';
          this.fundingMinimumMonthlyRevenue = funding?.minimumRevenuePerMonth || '';
          this.amountCurrentlyRaising = amountCurrentlyRaising === '' ? null : amountCurrentlyRaising;
          this.currentValuation = currentValuation === '' ? null : currentValuation;
          this.minimumRevenuePerMonth = minimumRevenuePerMonth === '' ? null : minimumRevenuePerMonth;
          this.selectedBusinessModels = businessModels.map((i) => i.name).join(', ');
          this.selectedFundingRoles = fundingRoles.map((i) => i.name).join(', ');
          this.ticketSizes = ticketSizes.map((i) => i.name).join(', ');
          this.geographyOfFocus = geographyOfFocus;
          this.fundingRaised = fundingRaised;
          this.pitchDeckUrl = pitchDeckUrl;
        }
      });
    }
  },
  methods: {
    ...mapActions(['fetchCompanyById']),
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
