var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),(_vm.loading)?_c('div',[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-progress-circular',{staticClass:"mx-auto flex",attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1):_c('v-card',[_c('DataTableHeader',{attrs:{"show-search-box":"","show-export":"","table-id":"organisation-table table","headers":_vm.headers},on:{"update:search":function($event){_vm.searchText = $event}}},[_c('div',{staticClass:"pl-2 ml-4 d-flex items-start",staticStyle:{"flex-grow":"1"}},[(_vm.searchType === 'location')?_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"label":"City","dense":"","outlined":"","hide-details":"auto","error-messages":_vm.showSelectedItemsError ? ['You must type a location'] : []},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}):(_vm.searchType === 'superConnectMe')?_c('v-autocomplete',{staticClass:"mb-3",staticStyle:{"max-width":"300px"},attrs:{"label":"Super Connect Me","outlined":"","dense":"","menu-props":{ offsetY: true },"items":[
            { name: 'Yes', id: true },
            { name: 'No', id: false } ],"item-text":"name","item-value":"id","hide-details":"auto","error-messages":_vm.showSelectedItemsError ? ['You must select one item'] : []},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}):_c('v-autocomplete',{staticStyle:{"max-width":"300px"},attrs:{"multiple":"","label":"Select Item","outlined":"","dense":"","menu-props":{ offsetY: true },"items":_vm.getItems,"item-text":"name","item-value":"id","hide-details":"auto","error-messages":_vm.showSelectedItemsError ? ['You must select at least one item'] : []},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"text-gray-700 py-1 text-14"},[_vm._v(_vm._s(_vm.selectedItems.length)+" item(s) Selected")]):_vm._e()]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.onSelectionChange}},[_vm._v("Apply")])],1)]),_c('v-data-table',{attrs:{"id":"organisation-table","data-has-actions":"true","headers":_vm.$filterHeaders(_vm.headers),"items":_vm.companyList,"sort-by":"id","search":_vm.searchText},on:{"click:row":_vm.viewItem},scopedSlots:_vm._u([{key:"item.outcomesText",fn:function(ref){
          var item = ref.item;
return _vm._l((item.outcomesText),function(outcome){return _c('v-chip',{key:outcome,attrs:{"small":""}},[_vm._v(" "+_vm._s(outcome)+" ")])})}},{key:"item.challengesText",fn:function(ref){
          var item = ref.item;
return _vm._l((item.challengesText),function(challenge){return _c('v-chip',{key:challenge,attrs:{"small":""}},[_vm._v(" "+_vm._s(challenge)+" ")])})}},{key:"item.techAreasText",fn:function(ref){
          var item = ref.item;
return _vm._l((item.techAreasText),function(techArea){return _c('v-chip',{key:techArea,attrs:{"small":""}},[_vm._v(" "+_vm._s(techArea)+" ")])})}},{key:"item.industriesText",fn:function(ref){
          var item = ref.item;
return _vm._l((item.industriesText),function(industry){return _c('v-chip',{key:industry,attrs:{"small":""}},[_vm._v(" "+_vm._s(industry)+" ")])})}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.showIndividuals(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")])],1)],1)]}},{key:"item.actions2",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center text-2xl mb-6"},[_c('span',{staticClass:"headline"},[_vm._v("Single Criteria Matching")])])}]

export { render, staticRenderFns }